import React from 'react';
import {graphql, Link} from 'gatsby';
import PropTypes from 'prop-types';

import {decodeHTML, getSlug} from 'assets/helper.js';
import Graphic from 'components/Graphic.js';
import Layout from 'components/Layout.js';
import Meta from 'components/Meta.js';
import Share from 'components/Share.js';
import Tile from 'components/Tile.js';
import i18n from 'data/i18n.en.json';


export default class Post extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            origin: '',
        };
    }

    componentDidMount() {
        this.setState({
            origin: window.location.origin,
        });
    }

    /* eslint max-len: 0 */
    formatedContent(content, postLink) {
        if(content.includes('<blockquote>')) {
            const regex = new RegExp(/<blockquote><p>(.*)<\/p><\/blockquote>/g);
            const blockQuoteMatches = content.match(regex);
            for(const match of blockQuoteMatches) {
                const quote = match.replace(/<[/a-z]+>/g, '');
                const link = `
                 <a
                     href="https://twitter.com/intent/tweet/?text=${quote}&url=${postLink}&via=teamhealthshare"
                             rel="noopener noreferrer"
                             target="_blank"
                             title="${i18n.social.shareTwitter}"
                 >
                     <blockquote>
                        <p>${quote}</p><em>&#8212;&nbsp;${i18n.social.tweetThis}</em>
                     </blockquote>
                 </a>
             `;
                content = content.replace(match, link);
            }
        }
        return content;
    }

    render() {
        const {postContent, relatedPosts} = this.props.data;
        const postLink = this.state.origin + this.props.data.postContent.path;
        const bannerImage = postContent.acf.banner_image || 'none';
        return (
            <Layout className="blog post">
                <Meta
                    description={postContent.acf.excerpt}
                    image={postContent.master_image}
                    title={decodeHTML(postContent.title)}
                    url={postLink}
                />
                <header>
                    <nav>
                        <Link to={this.props.data.blogPage.path}>
                            <Graphic name="iconBlog"/>
                            {i18n.blog.allPosts}
                        </Link>
                    </nav>
                    <h1>{decodeHTML(postContent.title)}</h1>
                </header>
                {postContent.master_image &&
                    <figure className="hero-image">
                        <img
                            alt={postContent.title}
                            src={postContent.master_image}
                        />
                    </figure>
                }
                {postContent.content &&
                    <section>
                        {postContent.categories &&
                            <nav>
                                <h4>{i18n.blog.postedIn}</h4>
                                <ul>
                                    {postContent.categories.map((node) =>
                                        <li key={node.wordpress_id}>
                                            <Link to={node.path}>
                                                {node.name}
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                                <h4>{i18n.blog.shareOn}</h4>
                                <Share
                                    copy={postContent.title}
                                    link={postLink}
                                />
                            </nav>
                        }
                        <article
                            dangerouslySetInnerHTML={{
                                __html: this.formatedContent(
                                    postContent.content,
                                    postLink,
                                ),
                            }}
                        />
                        {postContent.acf.banner_text &&
                            <a
                                className="banner"
                                href={`/${getSlug(
                                    postContent.acf.banner_link
                                )}`}
                                style={{backgroundImage: `url(${bannerImage})`,
                                }}
                            >
                                <span>
                                    <h4>{postContent.acf.banner_title}</h4>
                                    <p>{postContent.acf.banner_text}</p>
                                </span>
                                <Graphic name="iconArrowRight"/>
                            </a>
                        }
                    </section>
                }
                {relatedPosts.edges.length &&
                    <section className="tiles">
                        <h3>{i18n.blog.relatedArticles}</h3>
                        {relatedPosts.edges
                            .filter(({node}) =>
                                node.categories.filter(
                                    (nodeCat) => postContent.categories
                                        .map((postCat) => postCat.wordpress_id)
                                        .includes(nodeCat.wordpress_id)
                                ).length
                                && node.wordpress_id
                                    !== postContent.wordpress_id
                            )
                            .slice(0, 3)
                            .map(({node}, i) =>
                                <Tile
                                    copy={node.acf && node.acf.excerpt}
                                    image={node.master_image}
                                    key={i}
                                    title={decodeHTML(node.title)}
                                    to={node.path}
                                />
                            )
                        }
                    </section>
                }
            </Layout>
        );
    }
}

export const query = graphql`
    query($id: Int!) {
        blogPage: wordpressPage(wordpress_id: {eq: 53}) {
            path
        }
        postContent: wordpressPost(wordpress_id: {eq: $id}) {
            acf {
                banner_image
                banner_link
                banner_text
                banner_title
                excerpt
            }
            categories {
                name
                path
                wordpress_id
            }
            content
            master_image: jetpack_featured_media_url
            path
            title
            wordpress_id
        }
        relatedPosts: allWordpressPost (
            sort: {
                fields: date,
                order: DESC,
            },
        ) {
            edges {
                node {
                    acf {excerpt}
                    categories {wordpress_id}
                    link
                    master_image: jetpack_featured_media_url
                    title
                    path
                    wordpress_id
                }
            }
        }
    }
`;
