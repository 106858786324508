import React from 'react';
import PropTypes from 'prop-types';

import Graphic from 'components/Graphic.js';
import i18n from 'data/i18n.en.json';

export default class Share extends React.Component {
    static propTypes = {
        copy: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
    };

    /* eslint max-len: 0 */
    render() {
        return (
            <ul>
                <li>
                    <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${this.props.link}`}
                        rel="noopener noreferrer"
                        target="_blank"
                        title={i18n.social.shareFacebook}
                    >
                        <Graphic name="iconSocialFacebook" />
                    </a>
                </li>
                <li>
                    <a
                        href={`https://twitter.com/intent/tweet/?text=${this.props.copy}&url=${this.props.link}&via=teamhealthshare`}
                        rel="noopener noreferrer"
                        target="_blank"
                        title={i18n.social.shareTwitter}
                    >
                        <Graphic name="iconSocialTwitter" />
                    </a>
                </li>
                <li>
                    <a
                        href={`https://www.linkedin.com/shareArticle?mini=true&url=${this.props.link}&title=HealthShare&summary=${this.props.copy}`}
                        rel="noopener noreferrer"
                        target="_blank"
                        title={i18n.social.shareLinkedIn}
                    >
                        <Graphic name="iconSocialLinkedIn" />
                    </a>
                </li>
            </ul>
        );
    }
}
